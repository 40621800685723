@import '../../styles/styleguide/_constants';

.ForgotPasswordPage {
  .huddle {
    position: absolute;
    opacity: 0.65;
    font-size: 5.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.625vh;
  }

  .huddleFour {
    @extend .huddle;
    width: 12vh;
    height: 12vh;
    left: 24vw;
    top: 56vh;
  }
  .huddleTwo {
    @extend .huddle;
    width: 9vh;
    height: 9vh;
    left: 16vw;
    top: 44vh;
  }
  .huddleThree {
    @extend .huddle;
    width: 9.5vh;
    height: 9.5vh;
    right: 53vw;
    top: 20vh;
  }

  .logo {
    height: 2rem;
    object-fit: contain;
    width: auto;
  }

  @media (min-width: breakpoint('md')) {
    .formWrapper {
      width: 22rem;
    }
  }
}
