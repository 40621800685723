@import './_constants';
@import './_mixins';

.animation-fade-in {
  animation: fade-in duration('short') $transition-easing forwards;
}

.animation-fade-out {
  animation: fade-out duration('long') $transition-easing forwards;
}

.animation-slide-up {
  animation: slide-up duration('long') $transition-easing forwards;
}

.animation-toast-in {
  animation: toast-in duration('medium') $transition-easing forwards;
}

.animation-toast-out {
  animation: toast-out duration('short') $transition-easing forwards;
}

.delay-short {
  animation-delay: duration('short');
}

.delay-medium {
  animation-delay: duration('medium');
}

.delay-long {
  animation-delay: duration('long');
}

@keyframes toast-in {
  0% {
    transform: translate(0, 120%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes toast-out {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(120%, 0);
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(3.45vw);
  }

  100% {
    transform: translateY(0);
  }
}

.transition-all {
  transition: all duration('short') $transition-easing;
}
.transition-color {
  transition: color duration('short') $transition-easing;
}
.transition-bg-color {
  transition: background-color duration('short') $transition-easing;
}
.transition-bg-image {
  transition: background-image duration('short') $transition-easing;
}
.transition-opacity {
  transition: opacity duration('short') $transition-easing;
}
.transition-opacity--long {
  transition: opacity duration('short') $transition-easing;
}
.transition-transform {
  transition: transform duration('short') $transition-easing;
  &--slow {
    transition-duration: duration('long');
  }
}
.transition-height {
  transition: height duration('medium') $transition-easing;
}
.transition-width {
  transition: width duration('medium') $transition-easing;
}
.transition-right {
  transition: right duration('long') $transition-easing;
}
.transition-shadow {
  transition: box-shadow duration('medium') $transition-easing;
}
