@import '../../styles/styleguide/_constants';
@import '../../styles/styleguide/_mixins';

.Clickable {
  background: none;
  appearance: none;
  text-decoration: none;
  border: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.link {
  @include link;
  font-size: font('subbody');
  font-family: 'Lato';
}

.buttonPrimary {
  @include button;
  @include backgroundGradient--animated;

  color: color('white');
  height: 3.25rem;
  padding: 1rem;
  border-radius: 0.25rem;
  font-weight: $font-weight-bold;
  letter-spacing: 0.015rem;

  transition: box-shadow duration('short') $transition-easing;

  &:hover {
    @include shadowMedium;

    &:disabled {
      box-shadow: none;
    }
  }
}

.buttonSecondary {
  @include button;
  min-height: $inline-input-height;
  background-color: color('blue-delight');
  color: color('white');
  font-weight: $font-weight-bold;
  font-size: font('body');
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  &.negative {
    background-color: color('red');
  }

  transition: box-shadow duration('short') $transition-easing;

  &:hover {
    @include shadowMedium;

    &:disabled {
      box-shadow: none;
    }
  }
}

.buttonTertiary {
  @include button;
  min-height: $inline-input-height;
  background-color: color('white');
  color: color('shade-70');
  border: 2px solid color('shade-70');
  font-weight: $font-weight-bold;
  font-size: font('body');
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  transition: box-shadow duration('short') $transition-easing;

  &:hover {
    @include shadowSmall;

    &:disabled {
      box-shadow: none;
    }
  }
}

.buttonQuaternary {
  @include button;
  min-height: $inline-input-height;
  background-color: color('pink-delight');
  color: color('white');
  font-weight: $font-weight-bold;
  font-size: font('body');
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  transition: box-shadow duration('short') $transition-easing;

  &:hover {
    @include shadowMedium;

    &:disabled {
      box-shadow: none;
    }
  }
}

.buttonSmall {
  @include button;
  color: color('shade-70');
  font-weight: $font-weight-bold;
  font-size: font('small');
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  transition: box-shadow duration('short') $transition-easing;

  &:hover {
    @include shadowSmall;
  }
}

.buttonGrid {
  @include buttonGrid;
  text-decoration: none;

  span {
    font-size: font('small');
    font-weight: $font-weight-bold;
  }

  img {
    width: 100%;
    padding: 1rem;
  }
}

.buttonGridInline {
  @include buttonGrid;
  width: auto;
}

.buttonGridSmall {
  @include buttonGrid;
  width: $grid-button-size--small;
  height: $grid-button-size--small;
}
