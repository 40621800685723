@import '../../../styles/index.module.scss';

.EventAgendaForm {
  .cardWrapper {
    width: 80vw;
    max-width: 42.75rem;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .card {
      width: 100%;
    }
  }

  .addSession {
    margin-top: 1rem;
  }
}

$insert-height: 0.625rem;

.InsertAgendaSession {
  height: $insert-height;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: $insert-height;
  cursor: pointer;

  .insertButton {
    min-height: 2rem;
    height: 2rem;
    width: 5.125rem;
  }

  &.disabled {
    cursor: default;
  }

  &:hover {
    &:not(.disabled) {
      height: 1.5rem;

      .content {
        display: flex;
      }
    }
  }

  .content {
    display: none;
    flex: 1;
    align-items: center;

    .border {
      border-top: 2px dashed color('shade-70');
      width: 100%;
    }

    .buttonContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-left: -100%;
    }
  }
}
