@import '../../styles/styleguide/_mixins';

.AnalyticsReport {
  .iconPeople {
    @include square(4.34rem);
  }
  .iconTime {
    @include square(4.26rem);
  }
}
