@import '../../styles/styleguide/_constants';

.EditEvent {
  .internalNav ul li {
    width: 12rem;
  }

  @media (min-width: breakpoint('md')) {
    .countdown {
      position: absolute;
      top: $grid-button-size + 0.5rem;
      left: $grid-button-size + 2rem;
    }
  }
}
