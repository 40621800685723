@import '../../../styles/styleguide/constants';
@import '../../../styles/styleguide/mixins';

.EventLandingPageForm {
  color: color('shade-80');

  .bannerPreview {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    align-items: center;
    border: 0.5rem solid color('shade-10');
  }

  .logoUploadContainer {
    @include defaultDropZone;
  }

  .logoEmpty {
    border: none;
    height: 3.5rem;
    margin-top: 0.5rem;
  }

  .logoPreview {
    @include square(5rem);
    position: relative;
  }

  .socialMediaSharing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1rem;
  }

  .agenda {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 1rem;
  }

  .landingPageHelpTextBody {
    width: 40rem;
  }

  .landingPageInput {
    margin-bottom: 1.5rem !important;
  }

  .eventDescription {
    width: 100%;
    height: 18.75rem;
    margin-bottom: 1.5rem;

    &.disabled {
      opacity: 0.6;
    }
  }

  .sponsorLogos {
    line-height: 1.5rem;
  }

  .openBannerButton {
    background-color: color('shade-20');
    color: color('shade-70');
    font-size: font('detail');
    font-weight: $font-weight-normal;
    line-height: 1rem;
  }

  @media (min-width: breakpoint('md')) {
    .mainColumn {
      max-width: 40rem;
    }
  }
}
