@import '../../styles/styleguide/_constants';

.HomePage {
  .huddle {
    position: absolute;
    opacity: 0.65;
    font-size: 5.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.625vh;
  }
  .huddleSixteen {
    @extend .huddle;
    border: none;
    width: 22vh;
    height: 22vh;
    left: 20vw;
    top: 72vh;
  }
  .huddleTwo {
    @extend .huddle;
    width: 9vh;
    height: 9vh;
    left: 4vw;
    top: 40vh;
  }
  .huddleThree {
    @extend .huddle;
    width: 9.5vh;
    height: 9.5vh;
    right: 53vw;
    top: 20vh;
  }
  .huddleFive {
    @extend .huddle;
    width: 13vh;
    height: 13vh;
    left: 15vw;
    top: 50vh;
  }

  .image-fade-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .image-fade {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 2s ease-in-out; /* Longer duration for smoothness */
  }

  .image-fade.active {
    opacity: 1;
    z-index: 2; /* Ensure the active image is on top */
  }
}
