@import '../../styles/styleguide/mixins';

.CallbackPage {
  .loadingIcon {
    height: 24px;

    @include spinner(24px);
    animation-duration: 0.7s;
    border-width: 0.3rem;
    border-top-width: 0.3rem;
  }
}
