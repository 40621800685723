@import 'styleguide/_constants';
@import 'styleguide/_mixins';

$mui-active-input-overlay-color: rgba(#f4f4f6, 0.3);

.StripeElement {
  font-family: Source Code Pro, monospace;
  font-weight: 400;
  font-size: map-get($fonts, 'heading');
  line-height: map-get($line-heights, 'heading');
  letter-spacing: 0.00938em;
  padding: 18.5px 14px;
  width: 100%;
  color: color('shade-70');
  background-color: color('white');
  border: 1px solid color('shade-30');
  border-radius: map-get($border-radii, '_25');
}

.StripeElement--focus {
  border-width: 2px;
  padding: 17.5px 13px;
  border-color: color('blue-delight') !important;
  background-color: $mui-active-input-overlay-color;
}

.StripeElement--invalid {
  border-color: color('red') !important;
}

.StripeElement--webkit-autofill {
  background-color: color('shade-20') !important;
}
