@import '../../../styles/styleguide/_constants';
@import '../../../styles/styleguide/_mixins';

.logoUploadContainer {
  @include defaultDropZone;
}

.logoPreview {
  width: 20rem;
  height: 10rem;
}

.logoDisplay {
  @include logoDisplay;
  margin-right: 0.5rem;
}

.brandingTooltip {
  width: 20rem;
}
