$border-radii: (
  '_25': 0.25rem,
  '_5': 0.5rem,
  '1': 1rem,
  '2': 2rem,
  'circle': 9999px,
);

$breakpoints: (
  'xs': 365px,
  // iphone SE (320px)
  'sm': 500px,
  // iphone X (375px) and phablets
  'md': 770px,
  // ipad and smaller browser window
  'lg': 1024px,
  // laptop
  'xl': 1450px,
  // large desktop
  'xxl': 1800px,
  // extra large desktop
);

@function breakpoint($breakpoint-name) {
  @return map-get($breakpoints, $breakpoint-name);
}

@function dark($color) {
  @return mix($color, #000, 40%);
}
@function light($color) {
  @return mix($color, #fff, 50%);
}
@function lighter($color) {
  @return mix($color, #fff, 20%);
}
@function fade($color) {
  @return change-color($color, $alpha: 0.5);
}

$colors: (
  'black': #000000,
  'black_2': rgba(#000000, 0.2),
  'blue-delight--light': #cceefc,
  'blush': lighter(#de5663),
  'burgundy': dark(#de5663),
  'butter': #fbf6e7,
  'fog': rgba(#e9e9ec, 0.7),
  'leaf-green': light(#03caa5),
  'pink-delight': #c04483,
  'pink-delight--dark': dark(#c04483),
  'blue-delight': #3478ba,
  'blue-delight--dark': #004461,
  'logo-green--dark': dark(#03caa5),
  'logo-green--lighter': lighter(#03caa5),
  'logo-blue': #00a9f2,
  'logo-blue--deep': #0b94d0,
  'logo-blue--highlight': rgba(#00a9f2, 0.2),
  'logo-blue--dark': dark(#00a9f2),
  'logo-blue--lighter': lighter(#00a9f2),
  'logo-green': #03caa5,
  'logo-green--deep': #0ebda5,
  'logo-green--highlight': rgba(#03caa5, 0.1),
  'red': #de5663,
  'rose': light(#de5663),
  'silver': #f8f9fe,
  'shade-10': #f4f4f8,
  'shade-20': #e9e9ef,
  'shade-30': #c8c9d3,
  'shade-40': #a7a8b4,
  'shade-50': #868898,
  'shade-60': #676979,
  'shade-70': #4b4d58,
  'shade-80': #2f3037,
  'shade-90': #121216,
  'violet': #6716eb,
  'violet--lighter': lighter(#6716eb),
  'white': #ffffff,
);
@function color($color-name) {
  @return map-get($colors, $color-name);
}

$durations: (
  short: 0.125s,
  medium: 0.3s,
  long: 0.5s,
  extra-long: 1.25s,
);
@function duration($duration-name) {
  @return map-get($durations, $duration-name);
}

$font-lato: 'Lato', sans-serif;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-size-root: 16px;

$fonts: (
  'title': 3.25em,
  'subtitle': 2.5rem,
  'heading': 1.875rem,
  'subheading': 1.375rem,
  'body': 1.125rem,
  'subbody': 1rem,
  'detail': 0.875rem,
  'small': 0.75rem,
);

@function font($font-name) {
  @return map-get($fonts, $font-name);
}

$grid-button-size: 4.75rem;
$grid-button-size--small: 3.25rem;
$grid-button-size--xs: 2.25rem;

$inline-input-height: 2.5rem;

$inner-max-width: 64rem;

$internal-nav-width: 12rem;

$line-heights: (
  'title': 3.5rem,
  'subtitle': 2.75rem,
  'heading': 2.25rem,
  'subheading': 1.675rem,
  'body': 2rem,
  'subbody': 1rem,
  'detail': 1.375rem,
  'small': 1.125rem,
);

@function line-height($font-name) {
  @return map-get($line-heights, $font-name);
}

$spacing-units: (
  0.25rem,
  0.5rem,
  0.75rem,
  1rem,
  1.25rem,
  1.5rem,
  2rem,
  2.5rem,
  3rem,
  4rem,
  5rem,
  6rem,
  8rem
);

$transition-easing: ease-in-out;
