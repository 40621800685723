@import '../../styles/styleguide/constants';

.EventMedia {
  min-height: 24rem;

  .internalNav ul li {
    width: 12rem;
  }

  .wrapper {
    max-width: 90vw;
  }

  @media (min-width: breakpoint('md')) {
    min-height: 32rem;

    .wrapper {
      max-width: 60rem;
    }
  }
}
