@import '../../styles/styleguide/_constants.scss';

.smallCard {
  min-width: 16.5rem;
}

.map {
  canvas {
    max-width: 70vw;
  }
}

.photoBooth {
  .pieChartWrapper {
    display: flex;
    justify-content: center;

    .pieChart {
      max-width: 16.5rem;
    }
  }

  canvas {
    margin-left: -1rem;
  }
}

@media (min-width: breakpoint('lg')) {
  .smallCard {
    max-width: 16.5rem;
  }

  .photoBooth {
    max-width: 16.5rem;

    canvas {
      height: 15.625rem !important;
      width: 15.625rem !important;
      margin-left: -1rem;
    }
  }
}

@media (min-width: breakpoint('md')) {
  .map {
    max-width: 34rem;
    canvas {
      max-width: 32.5rem;
    }
  }
}
