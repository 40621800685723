@import 'mui-overrides';
@import 'stripe-overrides';
@import 'reset';
@import 'styleguide/_constants';
@import 'styleguide/_mixins';
@import 'styleguide/_display';
@import 'styleguide/_flexbox';
@import 'styleguide/_grid';
@import 'styleguide/_position';
@import 'styleguide/_spacing';
@import 'styleguide/_theme';
@import 'styleguide/_transitions';
@import 'styleguide/_typography';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@#{$font-weight-normal};#{$font-weight-bold}&display=swap');

:root {
  @include themeDefault;
  font-family: $font-lato;
  font-weight: $font-weight-normal;
  font-size: $font-size-root;
  color: color('shade-80');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  @extend .flex;
  @extend .flex-col;
  @extend .flex-1;
  min-height: 100vh;
  min-width: 100vw;
  overflow: auto;
}

.page {
  @extend .flex;
  @extend .flex-col;
  min-height: 100vh;

  &--withSidebar {
    @extend .page;

    @media (min-width: breakpoint('md')) {
      padding-left: $grid-button-size;
    }
  }
}

.page-inner {
  @extend .flex;
  @extend .flex-col;
  @extend .flex-1;

  &__content {
    padding: $grid-button-size + 1rem 1rem 1rem;

    @media (min-width: breakpoint('sm')) {
      padding: $grid-button-size + 1.25rem 2rem 1.25rem;
    }
  }
}

.inner-max-width {
  max-width: $inner-max-width;
  &--half {
    max-width: $inner-max-width * 0.5;
  }
}

.debug {
  border: 1px dashed #eec2c2;
}

a {
  color: color(logo-blue);
  text-decoration: none;
}
