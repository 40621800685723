@import '../../../styles/styleguide/_constants';
@import '../../../styles/styleguide/_mixins';
.EventInfoForm {
  @media (min-width: breakpoint('md')) {
    .mainColumn {
      max-width: 40rem;
    }
  }

  .error {
    color: color('red') !important;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.0125rem;
  }

  .adminControlsWrapper {
    width: 100%;

    .numberInput {
      color: inherit;

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      width: 1.75rem;
      font-size: $font-size-root;

      /* No arrows in firefox */
      input[type='number'] {
        -moz-appearance: textfield;
        padding: 0;
        text-align: center;
        background-position: 150% 50% !important;
      }
    }
  }

  span {
    height: 1.125rem;
  }

  .icon {
    width: 1.125rem;
    height: 1.125rem;
  }

  .eventDescription {
    width: 100%;
    height: 18.75rem;
    margin-bottom: 1.5rem;

    &.disabled {
      opacity: 0.6;
    }

    .aiPrompt {
      textarea {
        max-height: 100px;
        overflow: auto;
      }
    }
  }
}

.tooltipWrapper {
  white-space: inherit !important;
  max-width: 20rem;
  width: 20rem;
}
