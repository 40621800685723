@import '../../styles/styleguide/constants';
@import '../../styles/styleguide/mixins';

.SideBar {
  width: $grid-button-size;

  ul li {
    display: flex;
    flex-direction: column;
  }

  .mobileMenu {
    top: $grid-button-size;
    width: 100vw;
  }

  .homeButton {
    @include square($grid-button-size);
    &:hover {
      background-color: color('white');
    }
  }

  .signOutButton {
    border-top: 1px solid color('shade-20');
  }
}

@media (min-width: breakpoint('md')) {
  .SideBar {
    bottom: 0;
  }
}
