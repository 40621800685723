@import '../../styles/styleguide/constants';

.BoothReport {
  min-height: 24rem;

  .internalNav ul li {
    width: 12rem;
  }

  .wrapper {
    max-width: 80vw;
  }

  @media (min-width: breakpoint('md')) {
    min-height: 32rem;
  }
}
