@import '../../styles/styleguide/constants';
@import '../../styles/styleguide/mixins';

.EventLinkList {
  .star {
    font-size: font('detail');
    color: color('shade-80');
  }
}

.accessLinksHead {
  border-bottom: 2px solid color(shade-30);
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.col:nth-child(1) {
  width: 35%;
}
.col:nth-child(2) {
  justify-content: flex-start;
}
.col:first-child {
  justify-content: flex-start;
}
.col:last-child {
  flex: 1;
  justify-content: flex-end;
}

.accessLinkTitle {
  display: flex;
  flex-direction: row;
}

.helpIcon {
  font-size: 1rem !important;
}

.adminChip {
  svg {
    font-size: 0.75rem !important;
  }
}

.actionLink {
  color: color(shade-60) !important;
  font-size: font('detail');
  line-height: line-height('detail');
  font-weight: $font-weight-normal;
  text-decoration: underline;

  &:hover {
    @include link;
  }
}

.shareButtons {
  min-width: 10rem !important;
}

.shareButton {
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: font('body');
  color: color('shade-60');
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    @include link;
  }

  svg {
    margin-right: 0.5rem;
  }
}
