@import './_constants';

.flex {
  display: flex;
}
.flex-inline {
  display: inline-flex;
}
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-stretch {
  align-content: stretch;
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.gap-_25 {
  gap: 0.25rem;
}

.gap-_5 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}
.gap-1_5 {
  gap: 1.5rem;
}
.gap-2 {
  gap: 2rem;
}
.gap-4 {
  gap: 4rem;
}

@media (min-width: breakpoint('md')) {
  .md\:flex {
    display: flex;
  }
  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-baseline {
    align-items: baseline;
  }

  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:justify-stretch {
    justify-content: stretch;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:self-baseline {
    align-self: baseline;
  }

  .md\:self-stretch {
    align-self: stretch;
  }

  .md\:content-start {
    align-content: flex-start;
  }

  .md\:content-end {
    align-content: flex-end;
  }

  .md\:content-center {
    align-content: center;
  }

  .md\:content-between {
    align-content: space-between;
  }

  .md\:content-around {
    align-content: space-around;
  }

  .md\:content-stretch {
    align-content: stretch;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-2 {
    flex: 2;
  }

  .md\:flex-3 {
    flex: 3;
  }

  .md\:gap-1 {
    gap: 1rem;
  }
}

@media (min-width: breakpoint('lg')) {
  .lg\:flex {
    display: flex;
  }
  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-baseline {
    align-items: baseline;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:justify-stretch {
    justify-content: stretch;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-baseline {
    align-self: baseline;
  }

  .lg\:self-stretch {
    align-self: stretch;
  }

  .lg\:content-start {
    align-content: flex-start;
  }

  .lg\:content-end {
    align-content: flex-end;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-between {
    align-content: space-between;
  }

  .lg\:content-around {
    align-content: space-around;
  }

  .lg\:content-stretch {
    align-content: stretch;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-2 {
    flex: 2;
  }

  .lg\:flex-3 {
    flex: 3;
  }

  .lg\:gap-1 {
    gap: 1rem;
  }
}
