@import '../../styles/root.scss';

.RemoveLogoForm {
  background-color: white;

  .checkImage {
    margin-bottom: 1rem;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .removeButton {
    background-color: color('blush');
    color: color('shade-70');
  }

  .logoDisplay {
    @include logoDisplay;
    margin-left: 1rem;
  }
}
