@import '../../styles/styleguide/_constants';
@import '../../styles/styleguide/_mixins';

.EventCountdown {
  height: $grid-button-size - 1.5rem;

  .icon {
    @include square(1.125rem);
  }
}
