@import './constants';

/* 
TODO: Refactor some day!
Themed css vars are used to assign color vars used by @gatherly/lib components
Because this theme code was lifted from poorManVR, at times the context of these
vars is not relevant to what's going on in this repo per se.
example:
// poormanVR themes.scss
$color-border-focus: var(--color-primary-button-background);

// @gatherly/lib Checkbox.module.scss
input:focus {
  outline: 1px solid $color-border-focus; // in PoorManVR blue, EventManager green
}

in Event Manager, the "Primary" button background color is actually a gradient,
but the focus color is green, so we must do.
$theme-default: (
  'primary-button-background': color('logo-green'),
)
*/

$theme-default: (
  'text-primary': color('shade-80'),
  'text-secondary': color('shade-60'),
  'text-primary-inverted': color('white'),
  'text-secondary-inverted': color('shade-40'),
  'background-primary': color('white'),
  'background-secondary': color('shade-10'),
  'background-primary-inverted': color('shade-80'),
  'background-secondary-inverted': color('shade-90'),
  'backdrop': color('fog'),
  'border': color('shade-20'),
  'border-form-field': color('shade-30'),
  'border-inverted': color('shade-70'),
  'grid-button-background--active': #e9e9ef,
  'grid-button-background--hover': #f4f4f8,
  'grid-button-background': #ffffff,
  'grid-button-background--disabled': #ffffff,
  'grid-button-background--alert': #de5663,
  'grid-button-background--warn': #f8dde0,
  'grid-button-text--active': #2f3037,
  'grid-button-text--hover': #676979,
  'grid-button-text': #676979,
  'grid-button-text--disabled': #a7a8b4,
  'grid-button-text--alert': #ffffff,
  'grid-button-text--warn': #de5663,
  'button-border': transparent,
  'button-border-disabled': transparent,
  'primary-button-background--hover': #007abf,
  'primary-button-background': #007abf,
  'primary-button-background--disabled': #007abf,
  'primary-button-text--hover': #ffffff,
  'primary-button-text': #ffffff,
  'primary-button-text--disabled': #ffffff,
  'secondary-button-background--hover': #f4f4f8,
  'secondary-button-background': #e9e9ef,
  'secondary-button-background--disabled': #f4f4f8,
  'secondary-button-text--hover': #4b4d58,
  'secondary-button-text': #4b4d58,
  'secondary-button-text--disabled': #868898,
  'negative-button-background--hover': #e57882,
  'negative-button-background': #de5663,
  'negative-button-background--disabled': #f2adad,
  'negative-button-text--hover': #ffffff,
  'negative-button-text': #ffffff,
  'negative-button-text--disabled': #ffffff,
);

@mixin theme($theme-map) {
  @each $color-key, $color-value in $theme-map {
    --color-#{$color-key}: #{$color-value};
  }
}

@mixin themeDefault {
  @include theme($theme-default);
}
