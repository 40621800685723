@import '../../styles/styleguide/_constants';

.StripePricingTable {
  width: 100%; // Full width to occupy the container space
  padding: 1.5rem; // Add padding for spacing

  #stripe-pricing-table-container {
    width: 100%; // Ensure full width for embedded content
    display: flex;
    justify-content: center; // Center the content if it's narrower than the full container
  }

  @media (min-width: breakpoint('md')) {
    width: 100%; // Keep full width on larger screens
    padding: 2rem 0; // Adjust padding if needed for larger screens
  }
}
