@import '../../styles/styleguide/constants';

.Toolbar {
  height: $grid-button-size;
  left: $grid-button-size;
  min-width: 16rem;

  @media (min-width: breakpoint('md')) {
    &.withInternalNav {
      left: $grid-button-size + $internal-nav-width;
    }
  }
}
