@import '../../styles/styleguide/constants';

.Tabs {
  margin-bottom: -1px;
  .Tab {
    border-top: 1px solid color('shade-20');
    border-bottom-color: color('shade-40');
    transition: color duration('short') $transition-easing,
      border-color duration('short') $transition-easing;

    &:first-child {
      border-left-color: color('shade-20');
    }

    &.active {
      border-top-color: color('shade-40');
      border-left-color: color('shade-40');
      border-right-color: color('shade-40');
      border-bottom-color: color('white');
    }
    &:not(.active, :first-child) {
      border-left-color: color('white');
    }
  }
}
