@import '../../styles/styleguide/_constants';
@import '../../styles/styleguide/_mixins';

.DroppableUploader {
  .loading::before {
    content: ' ';
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
    @include spinner(1rem);
  }
  .emptyStateContent {
    background-color: rgba(color('white'), 0.9);
    border-radius: 0.5rem;
    padding: 0.5rem;
  }

  iframe {
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
}
