@import '../../../styles/styleguide/_constants';
@import '../../../styles/styleguide/_mixins';

$brochure_factor: 1.25rem;
$map_factor_desktop: 0.024rem;
$map_factor_mobile: 0.02rem;

.EditFloorForm {
  width: 100%;
  overflow: scroll;

  .brochureUploader {
    width: fit-content;
  }

  .brochurePreview {
    height: 11 * $brochure_factor;
    width: 8.5 * $brochure_factor;
  }

  .mapUploadWrapper {
    border: 0.5rem solid color('shade-10');
    width: fit-content;
  }

  .mapUploader,
  .meetingAreaBackground {
    height: 500 * $map_factor_mobile;
    width: 800 * $map_factor_mobile;
  }

  .meetingAreaBackground {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .error {
    color: color('red');
    font-size: font('small');
    padding-right: 1rem;
  }

  .mapPreview {
    width: 100%;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url('../../../assets/elevator-exit-overlay.png');
      background-size: cover;
      background-position: 50% 0%;
      background-repeat: no-repeat;
    }
  }
  .mapEmpty {
    width: 100%;
    height: 100%;
    background: rgba(color('white'), 0.4);
    border: none;
  }

  .loadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .loading {
      height: 24px;

      @include spinner(24px);
      animation-duration: 0.7s;
      border-width: 0.3rem;
      border-top-width: 0.3rem;
    }
  }

  .eventDescriptionWrapper {
    width: fit-content;

    .eventDescription {
      overflow: hidden;
      height: inherit;
      -webkit-mask-image: linear-gradient(
        to bottom,
        black 70%,
        transparent 100%
      );
      mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
      :first-child {
        border: none;
      }

      * {
        cursor: default;
        font-size: xx-small;
      }

      height: 11 * $brochure_factor;
      width: 8.5 * $brochure_factor;
    }
  }

  @media (min-width: breakpoint('md')) {
    .mapUploader,
    .meetingAreaBackground {
      height: 500 * $map_factor_desktop;
      width: 800 * $map_factor_desktop;
    }
  }
}
