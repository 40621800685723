.BroadcastRecordingsTable {
  .icon {
    height: 1.5rem;
    width: 1.25rem;
  }

  .actionsColumn {
    min-width: 5rem;
  }

  .table {
    border-collapse: collapse;

    th,
    td {
      padding: 0.5rem;
      height: 3rem;
    }

    td:empty::after {
      content: '\00a0';
    }

    tbody .icon {
      transform: translateY(0.375rem);
    }
  }
}
