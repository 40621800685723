@import '../../styles/styleguide/_constants';
@import '../../styles/styleguide/_mixins';

.Status {
  .loaderContainer {
    width: 4rem;
    height: 4rem;
  }

  .loader {
    @include spinner(3rem);
  }
}
