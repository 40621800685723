@import '../../styles/styleguide/_constants';
@import '../../styles/styleguide/_mixins';

.colorOption {
  width: 100%;
  margin: 0.25rem 0;

  &.firstColorOption {
    margin: 0;
  }
}

.colorOptions {
  [role='button'] {
    height: 20px !important;
  }
}
