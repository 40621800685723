@import '../../styles/styleguide/constants';

.NewEventForm {
  &.isMakingRequest {
    label {
      z-index: 0;
    }
  }

  .select {
    min-width: 16rem;
  }

  .disabled {
    opacity: 0.6;
  }

  .aiPrompt {
    textarea {
      max-height: 100px;
      overflow: auto;
    }
  }

  .backdrop {
    background: rgba(255, 255, 255, 0.85);

    .progressIndicator {
      max-width: 15.5rem;
      width: 100%;
      border-radius: 0.25rem;
      background-color: color('shade-30');

      .progressIndicatorBar {
        background-color: color('blue-delight');
      }
    }
  }

  @media (min-width: breakpoint('md')) {
    width: 33rem;
  }
}
