@import '../../styles/styleguide/_constants';

.PaginatedList {
  .table {
    border-collapse: collapse;

    th,
    td {
      border-bottom: 1px solid color('shade-20');
      padding: 0.5rem 1rem;
    }

    td:empty::after {
      content: '\00a0';
    }
  }
}
