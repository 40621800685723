@import '../../styles/styleguide/_constants';

.SignUpPage {
  .formWrapper {
    min-width: 18rem;
  }
  .logo {
    height: 2rem;
    object-fit: contain;
    width: auto;
  }

  @media (min-width: breakpoint('md')) {
    .formWrapper {
      width: 24rem;
    }
  }
}
