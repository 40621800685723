@import '../../../styles/styleguide/_constants';

.EventFormFooter {
  height: $grid-button-size;
  left: 0;

  @media (min-width: breakpoint('md')) {
    left: $grid-button-size;
  }
}
