@import '../../styles/styleguide/constants';

.ResourceCard {
  max-width: 22rem;

  .image {
    // use aspect ratio of map (800px x 500px)
    width: 18rem;
    height: 11.25rem;
  }

  @media (min-width: breakpoint('md')) {
    max-width: initial;
    width: 32rem;
    .image {
      transform: translateX(-2rem);
    }
  }
}
