@import '../../styles/styleguide/_mixins';

$mui-input-indent: 0.875rem;
.Select {
  .label {
    top: -0.3rem;
    left: $mui-input-indent;

    &[data-shrink='true'] {
      top: -0.4rem;
      left: $mui-input-indent - 0.25rem;
      background-color: color('white');
      padding: 0 0.35rem;
    }
  }

  .errorMessage {
    @include errorHelperText;
    margin-top: 0.2rem;
    margin-left: $mui-input-indent;
  }

  &.errored {
    .label {
      color: color('red');
    }
  }
}
