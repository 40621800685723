@import '../../styles/styleguide/_constants';

$tooltip-arrow-size: 0.25rem;
$tooltip-margin: 2.25rem;
$tooltip-color: color('silver');
$tooltip-background-color: color('shade-70');

.content {
  opacity: 0;
  position: absolute;
  border-radius: $tooltip-arrow-size;
  left: 50%;
  transform: translateX(-50%);
  font: font('detail');
  color: $tooltip-color;
  background: $tooltip-background-color;

  &::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: $tooltip-arrow-size;
    margin-left: -1 * $tooltip-arrow-size;
  }
}

.position {
  &--top {
    bottom: calc(100% + #{2 * $tooltip-arrow-size});
    &::before {
      top: 100%;
      border-top-color: color('shade-70');
    }
  }
  &--right {
    left: calc(100% + #{$tooltip-margin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &::before {
      left: -1 * $tooltip-arrow-size;
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-right-color: $tooltip-background-color;
    }
  }
  &--bottom {
    top: calc(100% + #{2 * $tooltip-arrow-size});
    &::before {
      bottom: 100%;
      border-bottom-color: $tooltip-background-color;
    }
  }
  &--left {
    left: auto;
    right: calc(100% + #{$tooltip-margin});
    top: 50%;
    transform: translateX(0) translateY(-50%);
    &::before {
      left: auto;
      right: -2 * $tooltip-arrow-size;
      top: 50%;
      transform: translateX(0) translateY(-50%);
      border-left-color: $tooltip-background-color;
    }
  }
}

.followCursor {
  bottom: auto;
  .Tooltip & {
    pointer-events: none;
  }
  &::before {
    display: none;
  }
}
