@import './_constants';

@mixin logoDisplay {
  height: 3.5rem;
  background-color: color('shade-10');
  padding: 0.75rem 1rem;
  min-width: 12rem;
  max-width: 12rem;
}

@mixin defaultDropZone {
  width: 100%;
  border: 1px dashed color('shade-30');
  height: 3.5rem;
  justify-content: center;
  border-radius: 0.125rem;
}

@mixin button {
  white-space: nowrap;
  font-weight: $font-weight-normal;
  font-family: $font-lato;
  font-size: font('body');
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

@mixin buttonGrid {
  @include button;
  height: $grid-button-size;
  width: $grid-button-size;
  color: color('shade-60');
  background-color: color('white');
  transition: background-color duration('short') $transition-easing;
  flex-direction: column;

  &:hover {
    background-color: color('shade-10');
  }
}

@mixin backgroundGradient {
  background: linear-gradient(
    -90deg,
    color('logo-blue') 0%,
    color('logo-green') 100%
  );
}

@mixin backgroundGradientFaded {
  background: linear-gradient(
    -90deg,
    fade(color('logo-green')) 0%,
    fade(color('logo-blue')) 100%
  );
}

@mixin backgroundGradient--animated {
  background: linear-gradient(
    45deg,
    color('logo-blue') 0%,
    color('logo-green') 33%,
    color('logo-blue') 66%,
    color('logo-green') 100%
  );
  background-size: 300%;
  &:hover {
    animation-name: scrollBackground;
    animation-duration: duration('long');
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
}

@mixin errorHelperText {
  color: color('red') !important;
  font-size: font('small');
  line-height: line-height('small');
  letter-spacing: 0.0125rem;
}

@mixin frosted {
  box-shadow: inset 0 0 2000px color('fog');
  backdrop-filter: blur(5px);

  &::after {
    @include shadowSmall;
  }
}

@mixin link {
  color: color('logo-blue') !important;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

@mixin pill {
  white-space: nowrap;
  font-weight: $font-weight-normal;
  font-family: $font-lato;
  font-size: font('detail');
  line-height: line-height('detail');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  padding: 1rem;
  border-radius: 1rem;
}

@keyframes scrollBackground {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100%;
  }
}

@mixin shadowSmall {
  box-shadow: 0 2px 4px color('black_2');
}

@mixin shadowMedium {
  box-shadow: 0 4px 8px color('black_2');
}

@mixin spinner($size) {
  width: $size;
  height: $size;

  transform: translate(-50%, -50%);
  border: 0.5rem solid color('shade-10');
  border-top: 0.5rem solid color('shade-60');
  border-radius: 50%;
  animation: spin 1.75s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin square($unit) {
  width: $unit;
  height: $unit;
}

@mixin textShadow {
  text-shadow: 1px 1px color('black_2');
}

@mixin square($unit) {
  width: $unit;
  height: $unit;
}
