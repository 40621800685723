@import '../../styles/styleguide/constants';

.SearchForm {
  .input {
    height: $inline-input-height;
    border-width: 0 1px 0 0;
    border-radius: 0.25rem 0 0 0.25rem;
    letter-spacing: 0.05rem;

    &::placeholder {
      font-family: $font-lato;
      font-style: italic;
      letter-spacing: initial;
    }
  }
  .submit {
    height: $inline-input-height;
    border-radius: 0 0.25rem 0.25rem 0;
  }
}
