@import 'styleguide/_constants';
@import 'styleguide/_mixins';

$mui-active-input-overlay-color: rgba(#f4f4f6, 0.3);

.MuiFormControl-marginNormal {
  margin: 0 !important;
}
.MuiOutlinedInput-notchedOutline {
  border-color: color('shade-30') !important;
}

.Mui-focused {
  &.MuiFormLabel-root {
    color: color('blue-delight') !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: color('blue-delight') !important;
    background-color: $mui-active-input-overlay-color;
  }
}

.MuiSelect-select:focus {
  background-color: $mui-active-input-overlay-color !important;
}

.MuiInput-underline:after {
  border-bottom-color: color('blue-delight') !important;
}

.MuiSwitch-colorSecondary {
  color: color('white') !important;
}
.Muiswitch-colorsecondary.Mui-checked:hover {
  background-color: $mui-active-input-overlay-color;
}

.MuiSwitch-colorSecondary {
  transition: background-color duration('medium') $transition-easing;
  &:hover {
    background-color: color('logo-blue--highlight') !important;
  }

  &.Mui-checked {
    & + .MuiSwitch-track {
      background-color: color('blue-delight') !important;
    }
  }
}

.MuiListItem-root {
  &.Mui-selected {
    background-color: color('logo-blue--highlight') !important;
  }
  &:hover {
    background-color: $mui-active-input-overlay-color;
  }
}

.MuiListItem-button {
  &.Mui-selected {
    background-color: color('logo-blue--highlight') !important;
  }
  &:hover {
    background-color: color('shade-10') !important;
  }
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected,
.MuiPickersClock-pin,
.MuiPickersClockNumber-clockNumberSelected,
.MuiPickersClockPointer-pointer,
.MuiPickerDTTabs-tabs {
  background-color: color('blue-delight') !important;
}
.MuiPickersClockPointer-thumb {
  border-color: color('blue-delight') !important;
}

.PrivateTabIndicator-colorSecondary-11,
.PrivateTabIndicator-colorSecondary-7 {
  background-color: color('shade-10') !important;
}

.Mui-error {
  &.MuiFormLabel-root {
    color: color('red') !important;
  }
  &.MuiFormHelperText-root {
    @include errorHelperText;
  }
}

.MuiOutlinedInput-root {
  font-family: $font-lato;
}
