@import '../../styles/root.scss';

.Logo {
  width: 100%;
  margin-top: 1rem;

  .logoDisplay {
    @include logoDisplay;
    margin-right: 0.5rem;
  }

  .form {
    width: 23rem !important;
    margin-right: 0.5rem !important;
    height: 3.5rem;
  }

  .fieldError {
    margin-bottom: 1rem !important;
  }

  .iconContainer {
    width: 1.5rem !important;
    height: 3.5rem !important;
    display: flex;
    align-items: center;

    .icon {
      cursor: pointer;
      color: color('shade-70');
    }
  }
}
