$factor: 0.75;
.NewFloorForm {
  width: 100%;
  .brochurePreview {
    position: relative;
    height: 11rem * $factor;
    width: 8.5rem * $factor;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
