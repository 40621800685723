@import '../../styles/styleguide/_constants';

.SignInForm {
  background-color: white; // Add this line to explicitly set the background
  min-width: 18rem;
  padding: 1.25rem 1.5rem; // Ensure padding for spacing inside the form
  border: 1px solid #ccc; // Optional: adds a subtle border
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: adds a light shadow

  .logo {
    height: 2rem;
    object-fit: contain;
    width: auto;
  }

  @media (min-width: breakpoint('md')) {
    width: 22rem;
  }
}
