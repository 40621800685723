.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.hidden {
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}

.visible {
  visibility: visible;
  width: initial;
  height: initial;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.overlay--fixed {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.t0 {
  top: 0;
}

.r0 {
  right: 0;
}

.b0 {
  bottom: 0;
}

.l0 {
  left: 0;
}

.z0 {
  z-index: 0;
}

.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}

.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}

.opacity-0 {
  opacity: 0;
}

.opacity-0_2 {
  opacity: 0.2;
}

.opacity-0_5 {
  opacity: 0.5;
}

.opacity-0_75 {
  opacity: 0.75;
}

.opacity-1 {
  opacity: 1;
}

.hover\:opacity-1:hover {
  opacity: 1;
}

.hover\:opacity-0_5:hover {
  opacity: 0.5;
}

.border-box {
  box-sizing: border-box;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.scroll-y {
  overflow-y: auto;
}

.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-3 {
  z-index: 3;
}
.z-4 {
  z-index: 4;
}

.circle {
  border-radius: 50%;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.margin-auto {
  margin: auto;
}

@media (min-width: breakpoint('sm')) {
  .sm\:relative {
    position: relative;
  }

  .sm\:absolute {
    position: absolute;
  }

  .sm\:fixed {
    position: fixed;
  }

  .sm\:sticky {
    position: sticky;
  }

  .sm\:hidden {
    visibility: hidden;
  }

  .sm\:visible {
    visibility: visible;
    width: initial;
    height: initial;
  }
}

@media (min-width: breakpoint('md')) {
  .md\:relative {
    position: relative;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:hidden {
    visibility: hidden;
  }

  .md\:visible {
    visibility: visible;
    width: initial;
    height: initial;
  }
}
