@import '../../styles/styleguide/_constants';

.VerificationPage {
  .formWrapper {
    width: 18rem;
  }
  .logo {
    height: 2rem;
    object-fit: contain;
    width: auto;
  }
  .verifyEmail {
    display: block;
    color: color('red') !important;
    text-decoration: none;
    font-size: 0.9rem;
    font-family: 'Lato';
    line-height: 20px;
  }

  @media (min-width: breakpoint('md')) {
    .formWrapper {
      width: 20rem;
    }
  }
}
