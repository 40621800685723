@import '../../styles/styleguide/_constants';

.Image {
  width: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity duration('medium') $transition-easing;

  &.loaded {
    opacity: 1;
  }

  &.background {
    background-color: color('silver');
    background-size: cover;
    background-position: 50% 0%;
    background-repeat: no-repeat;
  }
}
