@import './_constants';

// Font Scale rules

.title {
  font-size: map-get($fonts, 'title');
  line-height: map-get($line-heights, 'title');
}
.subtitle {
  font-size: map-get($fonts, 'subtitle');
  line-height: map-get($line-heights, 'subtitle');
}
.heading {
  font-size: map-get($fonts, 'heading');
  line-height: map-get($line-heights, 'heading');
}
.subheading {
  font-size: map-get($fonts, 'subheading');
  line-height: map-get($line-heights, 'subheading');
}
.body {
  font-size: map-get($fonts, 'body');
  line-height: map-get($line-heights, 'body');
}
.detail {
  font-size: map-get($fonts, 'detail');
  line-height: map-get($line-heights, 'detail');
}
.small {
  font-size: map-get($fonts, 'small');
  line-height: map-get($line-heights, 'small');

  &--spaced {
    @extend .small;
    letter-spacing: 0.03rem;
  }
}

.subbody {
  font-size: map-get($fonts, 'subbody');
  line-height: map-get($fonts, 'subbody');
}

// Other rules

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

.bold {
  font-weight: $font-weight-bold;
}

strong {
  @extend .bold;
}

.italic {
  font-style: italic;
}
em {
  @extend .italic;
}

.text-decoration-none {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

.events-none {
  pointer-events: none;
}

.events-all {
  pointer-events: all;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-progress {
  cursor: progress;
}

.nowrap {
  white-space: nowrap;
}

.wrap {
  white-space: normal;
}

.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-style-none {
  list-style: none;
}
