@use 'sass:math';

@import '../../../styles/styleguide/_constants';
@import '../../../styles/styleguide/_mixins';

.FloorCard {
  .number {
    width: 2rem;
    height: 2rem;
  }
  .brochurePreview {
    position: relative;
    height: 100%;
    min-height: 16rem;

    width: 100%;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .mapPreviewWrapper {
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: math.div(5, 8) * 100%; // constrain to 800px x 500px ratio (handy tool: https://ratiobuddy.com/)
    }
    > .mapPreviewContent {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      img {
        min-height: 100%;
      }
    }
  }

  .mapPreviewWrapperOverlay {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url('../../../assets/elevator-exit-overlay.png');
      background-size: contain;
      background-position: 50% 0%;
      background-repeat: no-repeat;
      transform: translateY(1px); // compensate for whitespace in overlay
    }
  }

  .mapPreview {
    height: fit-content;
  }

  .eventDescription {
    overflow: hidden;
    height: inherit;
    -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    :first-child {
      border: none;
    }

    * {
      cursor: default;
    }
  }

  @media (min-width: breakpoint('md')) {
    min-width: 36rem;
    max-width: 80rem;
  }
}
