@import './_constants';
@import './_mixins';

@each $name, $value in $border-radii {
  .br#{$name} {
    border-radius: $value;
  }
}

@each $name, $value in $colors {
  .color-#{$name} {
    color: $value;
  }
  .border-color-#{$name} {
    border-color: $value;
  }
  .bg-color-#{$name} {
    background-color: $value;
  }
  .hover\:color-#{$name}:hover {
    color: $value;
  }
  .hover\:border-color-#{$name}:hover {
    border-color: $value;
  }
  .hover\:bg-color-#{$name}:hover {
    background-color: $value;
  }

  @media (min-width: breakpoint('md')) {
    .md\:color-#{$name} {
      color: $value !important;
    }
    .md\:bg-color-#{$name} {
      background-color: $value !important;
    }
  }
}

.bg-color-gradient {
  @include backgroundGradient;
}

.bg-color-gradient-faded {
  @include backgroundGradientFaded;
}

.bg-frosted {
  @include frosted;
  &:before {
    content: '';
    position: absolute;
    pointer-events: none;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include shadowSmall;
  }
}

.border-1 {
  border-width: 1px;
  border-style: solid;
}
.border-l1 {
  border-left-width: 1px;
  border-left-style: solid;
}
.border-t1 {
  border-top-width: 1px;
  border-top-style: solid;
}
.border-r1 {
  border-right-width: 1px;
  border-right-style: solid;
}
.border-b1 {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.border-2 {
  border-width: 2px;
  border-style: solid;
}

.text-shadow {
  @include textShadow;
}
.shadow-sm {
  @include shadowSmall;
}
.shadow-md {
  @include shadowMedium;
}

.hover\:shadow-sm:hover {
  @include shadowSmall;
}
.hover\:shadow-md:hover {
  @include shadowMedium;
}
.none {
  display: none;
}

@media (min-width: breakpoint('md')) {
  .md\:none {
    display: none;
  }
}
