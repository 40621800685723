@use 'sass:math';

@import './_constants';

$columns: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12);

@each $value in $columns {
  .col-#{$value} {
    width: math.div($value, 12) * 100%;
  }
}

@each $breakpoint-name, $breakpoint-value in $breakpoints {
  @each $value in $columns {
    .#{$breakpoint-name}\:col-#{$value} {
      @media (min-width: breakpoint($breakpoint-name)) {
        width: math.div($value, 12) * 100% !important;
      }
    }
  }
}

.wauto {
  width: auto;
}

.hauto {
  height: auto;
}

.vh100 {
  height: 100vh;
}

.vw100 {
  width: 100vw;
}
