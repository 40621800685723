@import '../../styles/styleguide/_constants';

.Overlay {
  left: 0;

  .content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.9);
    transition: transform duration('short') $transition-easing;
  }

  &.withContent {
    .content {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @media (min-width: breakpoint('md')) {
    left: $grid-button-size;
  }
}
