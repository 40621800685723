@import '../../styles/styleguide/_constants';

.EventDetailPage {
  .content {
    min-height: calc(100vh - 2 * #{$grid-button-size});
    margin-bottom: $grid-button-size * 0.5;
  }

  @media (min-width: breakpoint('md')) {
    .content {
      min-height: calc(100vh - 3 * #{$grid-button-size});
    }
  }
}
