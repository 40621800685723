@import '../../styles/styleguide/constants';
@import '../../styles/styleguide/mixins';

.toastWrapper {
  max-width: 25rem;
}
.toast {
  @include shadowSmall;
  display: inline-block;
  transform: translate(0, 100%);
  width: auto;
  min-width: 18rem;
}
.removeButton {
  @include square('1rem');
}
